//import logop from '../../images/LOGOSM.PNG';
import logop from '../../images/LOGOFG.png';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import toast, {Toaster} from 'react-hot-toast';
import aviso from '../../images/Aviso.jpg';


const DatosTicket = () => {
    
    // use effect se ejecutará cada vez se renderice
    //const [counter,setConter]=useState(0);
    const [empresas, setEmpresas] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const notify = () => toast.custom((t) => (
        <div className="">
            <div className="grid grid-cols-1 content-center h-80">
                <div className="p-30 h-30">
                    <img src={aviso} className="w-1/2" alt="" />
                </div>
                <div className="grid grid-cols-1 content-center w-1/2">
                    <button
                        onClick={() => toast.dismiss(t.id)}
                        className="text-rose-900 text-white text-sm hover:text-gray-900 hover:bg-white p-4 rounded"
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
      ));
     /* useEffect(() => {
        
      fetch('http://facturaciongrupopampas.com/Empresas/Listado', {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(response => response.json())
            .then((data) => {
                setEmpresas(data.List);
                //console.log(data.List);
            });
    }, []);*/

    useEffect(() => {
        toast.remove();
        notify();
        axios
          //.get("http://facturaglobal.com.mx/TESTRI/Empresas/ListadoSM")
          //.get("http://facturaglobal.com.mx/TESTRI/Empresas/ListadoTC")
          .get("http://facturaglobal.com.mx/TESTRI/Empresas/Listado")
          .then(function (response) {
            // handle success            
            setEmpresas(response.data.List);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      }, []);


    // Hook para redireccionar
    const navigate = useNavigate();
    // validación y leer los datos
    const formik = useFormik({
        initialValues: {
            codEmpresa: '',
            numeroTicket: '',
            fechaFactura: fecha,
            total: '',
            totalFactura: ''
        },
        validationSchema: Yup.object({
            numeroTicket: Yup.string()
                .min(1, 'El número de folio es obligatorio')
                .required('El número de folio es obligatorio'),
            total: Yup.number()
                .min(1, 'Debes agregar un número')
                .required('El total del ticket es obligatorio'),
            totalFactura: Yup.number()
                .min(1, 'Debes agregar un número')
                .required('El total a facturar es obligatorio')

        }),
        onSubmit: datos => {
            console.log(datos);
            navigate('/DatosCliente/', { state: datos });
            //<Link to="/DatosCliente/1">Producto 1</Link>
        }
    });
    return (<>
      <Toaster/>
        <div className="">
            <div className="mt-3 flex justify-center">
                <img src={logop} className="w-1/2" alt="" />
            </div>
            <h1>Datos tickets</h1>
            <div className="flex justify-center mt-10">
                <div className="w-full max-w-3xl">
                    <form
                        onSubmit={formik.handleSubmit}
                    >

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">Restaurante</label>
                            <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                name="codEmpresa"
                                id="codEmpresa"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                {empresas.map((empresa, index) => (
                                    <option key={empresa.codEmpresa} value={empresa.codEmpresa}>
                                        {empresa.nombreEmp}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="numeroTicket">Número de folio del ticket:</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="numeroTicket"
                                type="text"
                                placeholder="Número del folio del ticket"
                                value={formik.values.numeroTicket}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        {formik.touched.numeroTicket && formik.errors.numeroTicket ? (
                            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5" role="alert">
                                <p className="font-bold">Hubo un error:</p>
                                <p>{formik.errors.numeroTicket} </p>
                            </div>
                        ) : null}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">Fecha de consumo</label>
                            <div>
                                <DatePicker
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={setFecha} value={fecha} />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="total">Total consumo</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="total"
                                type="number"
                                placeholder="$20"
                                min="0"
                                value={formik.values.total}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        {formik.touched.total && formik.errors.total ? (
                            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5" role="alert">
                                <p className="font-bold">Hubo un error:</p>
                                <p>{formik.errors.total} </p>
                            </div>
                        ) : null}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="totalFactura">Total Factura</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="totalFactura"
                                type="number"
                                placeholder="$20"
                                min="0"
                                value={formik.values.totalFactura}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        {formik.touched.totalFactura && formik.errors.totalFactura ? (
                            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5" role="alert">
                                <p className="font-bold">Hubo un error:</p>
                                <p>{formik.errors.totalFactura} </p>
                            </div>
                        ) : null}
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <input
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-yellow-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            value="Siguiente"
                        />
                            </div>
                        
                    </form>
                </div>
            </div>


        </div>
    </>);
}
export default DatosTicket;