
//import logo3 from '../../images/SM3.jpg';
//import logo4 from '../../images/SM4.jpg';
// logos pampas
import logo3 from '../../images/FG3.jpg';
import logo4 from '../../images/FG4.jpg';

const LadoDer = () => {
    return ( <>
        <div className="">
            <img src={logo3} className="hidden lg:block" alt="" />
            <img src={logo4} className="hidden lg:block" alt="" />
        </div>
    </> );
}
 
export default LadoDer;